import { graphql, Link } from "gatsby";
import React from "react";
import Footer from "./../components/footer/footer";
import Header from "./../components/header/header";
import Seo from "./../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import * as s from "../styles/404.module.css";
const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  const scrollToForm = "#";
  return (
    <>
      <Seo
        title={"404: Not Found"}
        description={""}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main className={s.main404}>
        <section>
          <div className="container">
            <div className="d-flex flex-column align-items-center">
              <h1>Oops!</h1>
              <h2>There is no fish</h2>
              <StaticImage
                className={s.img404}
                placeholder="none"
                alt="shadow"
                src="../images/404.png" />
                <p>Unfortunately, something went wrong and we couldn't find the page you requested.
                  Please visit the homepage</p>
                <Link to="/" className={`bg-orange cursor-pointer ${s.homeLink}`}>Go to Homepage</Link>
            </div>
          </div>
        </section>
      </main>
      <Footer scrollToForm={scrollToForm}></Footer>
    </>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
